export const SIDEBAR_OPEN = 'SIDEBAR_OPEN'
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE'
export const GET_PRODUCTS_BEGIN = 'GET_PRODUCTS_BEGIN'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'
export const GET_SINGLE_PRODUCT_BEGIN = 'GET_SINGLE_PRODUCT_BEGIN'
export const GET_SINGLE_PRODUCT_SUCCESS = 'GET_SINGLE_PRODUCT_SUCCESS'
export const GET_SINGLE_PRODUCT_ERROR = 'GET_SINGLE_PRODUCT_ERROR'
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS'
export const SET_GRIDVIEW = 'SET_GRIDVIEW'
export const SET_LISTVIEW = 'SET_LISTVIEW'
export const UPDATE_SORT = 'UPDATE_SORT'
export const SORT_PRODUCTS = 'SORT_PRODUCTS'
export const UPDATE_FILTERS = 'UPDATE_FILTERS'
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const GET_CATALOGS_BEGIN = 'GET_CATALOGS_BEGIN'
export const GET_CATALOGS_SUCCESS = 'GET_CATALOGS_SUCCESS'
export const GET_CATALOGS_ERROR = 'GET_CATALOGS_ERROR'
export const GET_RELATED_PRODUCT_BEGIN = 'GET_RELATED_PRODUCT_BEGIN'
export const GET_RELATED_PRODUCT_SUCCESS = 'GET_RELATED_PRODUCT_SUCCESS'
export const GET_RELATED_PRODUCT_ERROR = 'GET_RELATED_PRODUCT_ERROR'