import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import stageDecor from '../assets/stageDecor.jpg'
import marigold from '../assets/marigold.jpg'

const Hero = () => {
  return <Wrapper className="section-center">
    <article className="content">
      <h1 style={{ textTransform: 'none' }}>
        Send <span style={{ color: 'red' }}>flowers</span> like <br /> you mean it.
      </h1>
      <p>Where flowers are our inspiration to create lasting memories. Whatever the occasion, our flowers will make it special.
      </p>
      <Link to="/products" className="btn hero-btn">shop now</Link>
    </article>
    <article className="img-container">
      <img src={stageDecor} alt="stage decoration" className="main-img" />
      <img src={marigold} alt="marigold in pot" className="accent-img" />
    </article>
  </Wrapper>
}

const Wrapper = styled.section`
  min-height: 50vh;
  display: grid;
  place-items: center;
  .img-container {
    display: none;
  }

  p {
    line-height: 2;
    max-width: 45em;
    margin-bottom: 2rem;
    color: var(--clr-grey-5);
    font-size: 1rem;
  }
  @media (min-width: 992px) {
    height: calc(100vh - 8rem);
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    h1 {
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.25rem;
    }
    .hero-btn {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
    }
    .img-container {
      display: block;
      position: relative;
    }
    .main-img {
      width: 100%;
      height: 500px;
      position: relative;
      border-radius: var(--radius);
      display: block;
      object-fit: cover;
    }
    .accent-img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 250px;
      transform: translateX(-50%);
      border-radius: var(--radius);
    }
    .img-container::before {
      content: '';
      position: absolute;
      width: 10%;
      height: 80%;
      background: var(--clr-primary-9);
      bottom: 0%;
      left: -8%;
      border-radius: var(--radius);
    }
  }
`

export default Hero
