import React from 'react'

const Loading = () => {
  return <div className="loading">
    <div className="dot"></div>
    <div className="dot2"></div>
  </div>
}

export default Loading
