import React from 'react';
import styled from 'styled-components';
import { useFilterContext } from '../context/filter_context';
import { useProductsContext } from '../context/products_context';

const Filters = () => {
  const {
    filters: { text, category },
    updateFilters
  } = useFilterContext();
  const { categories } = useProductsContext();

  return (
    <Wrapper>
      <div className='content'>
        <form onSubmit={(e) => e.preventDefault()}>
          {/* Search Input */}
          <div className='form-control'>
            <input type='text' name='text' placeholder='search' className='search-input' value={text} onChange={updateFilters} />
          </div>
        </form>
      </div>
      {/* End Of Search Input */}

      {/* Categories */}
      <div className='form-control'>
        <h5>category</h5>
        <div>
          <button
            key={0}
            onClick={updateFilters}
            type='button'
            name='category'
            value={'all'}
            className={`${category === 'all' ? 'active' : null}`}
          >
            All
          </button>
          {categories.map((c, index) => {
            return (
              <button
                key={index}
                onClick={updateFilters}
                type='button'
                name='category'
                value={c.id}
                className={`${category === c.id.toString() ? 'active' : null}`}
              >
                {c.name}
              </button>
            );
          })}
        </div>
      </div>
      {/* End Of Categories */}

    </Wrapper>
  );
};

const Wrapper = styled.section`
  .form-control {
    margin-bottom: 1.25rem;
    h5 {
      margin-bottom: 0.5rem;
    }
  }
  .search-input {
    padding: 0.5rem;
    background: var(--clr-grey-10);
    border-radius: var(--radius);
    border-color: transparent;
    letter-spacing: var(--spacing);
  }
  .search-input::placeholder {
    text-transform: capitalize;
  }

  button {
    display: block;
    margin: 0.25em 0;
    padding: 0.25rem 0;
    text-transform: capitalize;
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    letter-spacing: var(--spacing);
    color: var(--clr-grey-5);
    cursor: pointer;
  }
  .active {
    border-color: var(--clr-grey-5);
  }
  .company {
    background: var(--clr-grey-10);
    border-radius: var(--radius);
    border-color: transparent;
    padding: 0.25rem;
  }
  .colors {
    display: flex;
    align-items: center;
  }
  .color-btn {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #222;
    margin-right: 0.5rem;
    border: none;
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.5rem;
      color: var(--clr-white);
    }
  }
  .all-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    opacity: 0.5;
  }
  .active {
    opacity: 1;
  }
  .all-btn .active {
    text-decoration: underline;
  }
  .price {
    margin-bottom: 0.25rem;
  }
  .shipping {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    text-transform: capitalize;
    column-gap: 0.5rem;
    font-size: 1rem;
  }
  .clear-btn {
    background: var(--clr-red-dark);
    color: var(--clr-white);
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
  }
  @media (min-width: 768px) {
    .content {
      position: sticky;
      top: 1rem;
    }
  }
`;

export default Filters;
