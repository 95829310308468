export const links = [
  {
    id: 1,
    text: 'home',
    url: '/',
  },
  {
    id: 2,
    text: 'products',
    url: '/products',
  },
  {
    id: 3,
    text: 'blogs',
    url: '/blogs',
  },
  {
    id: 4,
    text: 'about us',
    url: '/about',
  },
  {
    id: 5,
    text: 'contact us',
    url: '/contact-us',
  }
]

export const videoLink = [
  {
    link: <iframe src="https://www.youtube.com/embed/FFiVWrkA_G0" title="Temple Decoration" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  },
  {
    link: <iframe src="https://www.youtube.com/embed/lJ-4u1m6SZs" title="Temple Decoration" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  },
  {
    link: <iframe src="https://www.youtube.com/embed/lmjPsMSyxQ0" title="Temple Decoration" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  }  
]

export const blogs = [
  {
    id: 'artificial-flowers-a-timeless-beauty-for-your-home-decor',
    name: 'Artificial Flowers - A Timeless Beauty for Your Home Decor',
    description: `
    <p>Flowers are a classic representation of grace and beauty. For generations, people have used them to embellish houses and provide a relaxing atmosphere. Fresh flowers, on the other hand, need maintenance and care, and their beauty quickly wears off. On the other hand, artificial flowers provide a low-maintenance, long-lasting option. In this article, we'll look at the advantages of fake flowers and how they may enliven your home's interior design.</p>
    <p><h4>advantages of artificial flowers</h4>
      <ol>
          <li><b>Longevity:</b> The lifetime of artificial flowers is one of their main advantages. They last longer than fresh flowers and may be appreciated for months or even years since they don't wilt or lose their beauty.</li>
          
          <li><b>Low maintenance:</b> Compared to real flowers, artificial flowers require very little care. They can be readily cleaned with a moist cloth and don't need sunshine, pruning, or water.</li>
          
          <li><b>Cost-Effective:</b> Artificial flowers are a cost-effective choice even if their initial price may be more than that of fresh flowers. They may be utilised several times, ultimately saving you money.</li>

          <li><b>Variety:</b> A broad range of colours, sizes, and forms are available in artificial flowers, providing you many alternatives to achieve the aesthetic you want. You may combine various flowers to make one-of-a-kind bouquets that reflect your preferences and aesthetic.</li>
      </ol>    
    </p>
    <p><h4>artificial flowers as part of your home's decor</h4>
      <ol>
          <li><b>Centrepieces:</b> For dining tables, coffee tables, and mantels, artificial flowers create lovely centrepieces. They can be arranged in a vase or basket to make a beautiful presentation.</li>
          
          <li><b>Wall decor:</b> Hang a floral wreath or a bouquet on your walls to make a bold statement. It will give your room a splash of colour and become a centre of attention.</li>
          
          <li><b>Floral Arrangements:</b> By arranging various flowers in a vase or basket, you may make a stunning floral arrangement. You can combine and contrast various colours, sizes, and textures to produce a distinctive and captivating show.</li>
      </ol>    
    </p>
    <p>At Mahaveer flowers, We have a broad selection of artificial flowers in a variety of hues and sizes. Our flowers have the appearance and texture of real flowers since they are produced from premium materials. We offer the ideal flowers for you, whether you want to design a gorgeous centrepiece or lovely floral arrangement. Visit our <a href="https://www.mahaveerflowers.in">website</a> to look through our selection and enhance your home's decor with timeless beauty.</p>
    `,
    image: 'https://res.cloudinary.com/dupeaisq7/image/upload/v1674126065/cuaxjj7bsokkfwooewd4.jpg'
  },
  {
    id: 'say-it-with-mahaveer-flowers-the-perfect-expression-of-love',
    name: 'Say it with Mahaveer Flowers - The Perfect Expression of Love!',
    description:`
    <p>Welcome to <a href="https://www.mahaveerflowers.in">Mahaveer Flowers</a>, the online flower shop with a wide selection of fresh and vibrant flowers for all occasions!</p>

    <p>At Mahaveer Flowers, we understand that flowers are more than just a gift; they're an expression of love, appreciation, and admiration. That's why we strive to provide our customers with the best quality flowers at the most competitive prices. Our goal is to make sure that you can find the perfect flower arrangement for any occasion.</p>

    <p>Whether you're looking for a bouquet of roses for your anniversary or a basket of lilies for your mother's birthday, we have something for everyone. We offer a variety of flower arrangements in different sizes and colors to suit your needs. From classic red roses to exotic orchids, we have something for everyone.</p>

    <p>Our selection of flowers is carefully curated to ensure that each arrangement is unique and special. We also take pride in our customer service and strive to make sure that each customer is satisfied with their purchase. Our team of experts is always available to answer any questions you may have and help you find the perfect flower arrangement for your special occasion.</p>

    <p>At Mahaveer Flowers, we believe that flowers should be enjoyed by everyone, no matter what their budget is. That's why we offer a wide range of prices so that everyone can find something within their budget. Whether you're looking for something simple or something extravagant, we have something for everyone.</p>

    <p>The benefits of ordering from Mahaveer Flowers are numerous:
      <ul>
        <li>
        <b>Quality:</b> Our flowers are hand-picked and carefully inspected to ensure that only the freshest and highest quality blooms are used in our arrangements.
        </li>
        <li>
        <b>Variety:</b> We offer a wide variety of flowers in different sizes and colors so that you can find the perfect arrangement for any occasion.
        </li>
        <li>
        <b>Price:</b> We offer competitive prices so that everyone can find something within their budget.
        </li>
        <li>
        <b>Convenience:</b> Our online store makes it easy to order your flowers without ever leaving your home.
        </li>
        <li>
        <b>Customer Service:</b> Our team of experts is always available to answer any questions you may have and help you find the perfect flower arrangement for your special occasion.
        </li>
      <ul>
    </p>

    <p>At <a href="https://www.mahaveerflowers.in">Mahaveer Flowers</a>, we strive to provide our customers with the best quality flowers at the most competitive prices. Whether you're looking for a classic bouquet of roses or something more exotic, we have something for everyone. So come visit us today and let us help you find the perfect flower arrangement for your special occasion!</p>
    `,
    image: 'https://res.cloudinary.com/dupeaisq7/image/upload/v1674451681/kgzrjkelqizm6wohk8rw.jpg'
  }
]